import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter, { RouteConfig } from 'vue-router'
import './assets/css/style.css'
import {getRequest} from "@/api/api";

Vue.use(VueRouter);
Vue.prototype.getRequest = getRequest;
// 定义全局变量
Vue.prototype.$headerGlobal = {
  select:1,
};

Vue.config.productionTip = false;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

