import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/application',
        name: 'application',
        component: () => import('../views/ApplicationView.vue'),
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    },
    {
        path: '/policies',
        name: 'policies',
        component: () => import('../views/PoliciesView.vue')
    },
    {
        path: '/membership',
        name: 'membership',
        component: () => import('../views/MembershipView.vue')
    },
    {
        path: '/video',
        name: 'video',
        component: () => import('../views/VideoView.vue')
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/SearchView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/ServiceView.vue')
    }, {
        path: '/service1',
        name: 'service1',
        component: () => import('../views/ServiceView1.vue')
    }, {
        path: '/service2',
        name: 'service2',
        component: () => import('../views/ServiceView2.vue')
    }, {
        path: '/service3',
        name: 'service3',
        component: () => import('../views/ServiceView3.vue')
    },
    {
        path: '/NewsDetails',
        name: 'NewsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    }, {
        path: '/policies1',
        name: 'policies1',
        component: () => import('../views/PoliciesView1.vue')
    }, {
        path: '/policies2',
        name: 'policies2',
        component: () => import('../views/PoliciesView2.vue')
    }, {
        path: '/policies3',
        name: 'policies3',
        component: () => import('../views/PoliciesView3.vue')
    }, {
        path: '/policies4',
        name: 'policies4',
        component: () => import('../views/PoliciesView4.vue')
    }, {
        path: '/policies5',
        name: 'policies5',
        component: () => import('../views/PoliciesView5.vue')
    }, {
        path: '/policies6',
        name: 'policies6',
        component: () => import('../views/PoliciesView6.vue')
    }, {
        path: '/policies7',
        name: 'policies7',
        component: () => import('../views/PoliciesView7.vue')
    }, {
        path: '/policies8',
        name: 'policies8',
        component: () => import('../views/PoliciesView8.vue')
    }, {
        path: '/memberTraining',
        name: 'memberTraining',
        component: () => import('../views/MemberTraining.vue')
    }, {
        path: '/hytz',
        name: 'hytz',
        component: () => import('../views/hytz.vue')
    }, {
        path: '/zn',
        name: 'zn',
        component: () => import('../views/Membership/zn.vue')
    }, {
        path: '/jjcxc',
        name: 'jjcxc',
        component: () => import('../views/Membership/jjcxc.vue')
    }, {
        path: '/sf',
        name: 'sf',
        component: () => import('../views/Membership/sf.vue')
    }, {
        path: '/nyjc',
        name: 'nyjc',
        component: () => import('../views/Membership/nyjc.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
